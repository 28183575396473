/* thai */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-thai.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-vietnamese.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-latin-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Kanit Regular'), local('Kanit-Regular'), url(../public/fonts/Kanit/Kanit-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
    box-sizing: border-box;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    user-select: none;
    // overflow: hidden;
}

html, body {
    margin: 0;
    font-family: 'Kanit', sans-serif;
}

.chooseFile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    input {
        display: none;
    }

    button {
        background-color: #fff;
        border: 1px solid #000;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
    }

}

.col-2 {
    display: flex;
    width: 100vw;
    height: 100vh;

    .content-side {
        width: 50%;
        height: 100%;
    }
}

.conferences {
    padding: 20px;
    padding-left: 50px;
    display: block;
    width: 100%;
    height: 100%;

    .length {
        margin: 0;
        position: absolute;
        left: 10px;
        width: 30px;
        text-align: center;
    }

    .setItem {
        padding: 10px;
        margin-bottom: 20px;
        min-height: 100px;
        border: transparent 4px solid;
        background-color: #f2e7c3;

        .item {
            position: relative;
            margin: 5px;
            display: inline-block;
            width: 100%;
            height: 50px;
            background-color: white;
            border: 1px solid #000;
    
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 24px;
                color: #fff;
                text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            }
        }
    }

    
}

.output {
    padding: 20px;
    padding-left: 0;
    display: block;
    width: 100%;
    height: 100%;
    height: 100%;
    .item {
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
        min-height: 100px;
        border: transparent 4px solid;
        background-color: #e1e1e1;

        .itemConf {
            position: relative;
            margin: 5px;
            display: inline-block;
            width: 150px;
            height: 200px;
            background-color: white;
            border: 1px solid #000;
    
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 24px;
                color: #fff;
                text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            }
        }
    }
}

.card-list {
    padding: 20px;
    padding-left: 0;
    display: block;
    width: 100%;
    height: 100%;
    height: 100%;
    .item {
        position: relative;
        margin: 5px;
        display: inline-block;
        width: 150px;
        height: 200px;
        background-color: white;
        border: 1px solid #000;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            color: #fff;
            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        }
    }
}

.success {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    padding: 20px;
    min-width: 400px;
    color: white;
    max-width: 800px;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    z-index: 1000;
    white-space: pre-line;
    border: 3px #111 solid;
    box-shadow: 0 0 5px 1px #353535;
    overflow: auto;
    opacity: .75;

    h1 {
        margin-top: 0;
    }
}